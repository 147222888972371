import React, { useEffect } from 'react'
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "gatsby";
import Layout from '../components/Layout'
import Hero from '../components/Mobileapp/hero'
import allApiData from "../api";
import SeoheadData from "../siteScriptData";
import Seo from '../components/Seo'

function Htmlsitemap() {
    useEffect(() => {
        allApiData.aosinit()
    }, []);
    return (
        <Layout>
            <Seo SeoData={SeoheadData.htmlSitemap}></Seo>
            <div className='html-sitemap'>
                <Hero title="Explore us" def=" Our sitemap will navigate you to know the content of our website and go to webpages directly wherever you want to reach." button_t={true}>
                    <div className=" mt-lg-5 mb-lg-3">
                        {/* <img src="../assets/img/contact/hero_img/contact-us-mobile.svg" className=" img-fluid" alt="contact-us-mobile" /> */}
                        <img src="../assets/img/html-sitemap/html-sitemap.svg" className="img-fluid" alt="html-sitemap" />
                    </div>
                </Hero>

                <div className="direct-linking footer bg-transparent pb-100 pt-lg-5 mt-5 mb-lg-5">
                    <Container>
                        <div className="footer-box">
                            <div className="heading">
                                <h2>Direct Links</h2>
                            </div>
                            <Row className='mt-lg-5 mt-4'>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/">Home </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/our-technology">Technologies</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/portfolio">Portfolio</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/quotation">Request For Quote </Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>

                        <div className="footer-box pt-lg-5 pt-4">
                            <div className="heading">
                                <h2>Services</h2>
                            </div>
                            <Row className='mt-lg-5 mt-4'>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/app-development-company">App Development </Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/game-development-company">Game Development</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/web-development-company">Web Development</Link>
                                        </li>
                                    </ul>
                                </Col>
                                {/* <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/nft-development-company">NFT Development</Link>
                                        </li>
                                    </ul>
                                </Col> */}
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/hire-offshore-developers">Hire Offshore Developers</Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>

                        <div className="footer-box pt-lg-5 pt-4">
                            <div className="heading">
                                <h2>Company</h2>
                            </div>
                            <Row className='mt-lg-5 mt-4'>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/about-us">About Vasundhara</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/why-vasundhara">Why Vasundhara</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/blogs">Blogs</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/case-study">Case Study</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/industries">Industries</Link>
                                        </li>
                                    </ul>
                                </Col>

                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/careers">Careers</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/faqs">FAQs</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/client-testimonials">Testimonials</Link>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xl={4} lg={4} md={6}>
                                    <ul>
                                        <li>
                                            <Link to="/contact-us">Contact Us</Link>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </div>
        </Layout>
    )
}

export default Htmlsitemap
